import React from 'react';
import { BlockLabel } from '../BlockLabel';
import styles from './styles.module.scss';

export const Contacts: React.FC = () => (
  <section className={styles.contacts} id='contacts'>
    <div className='content-center'>
      <BlockLabel label='Контакты' />
      <div className={styles.content}>
        <div className={styles.main}>
            <div className={styles.contact}>
                <span className={styles.label}>телефон</span>
                <a href='tel: +7 910 870 33 77' className={styles.link}>+7 910 870 33 77</a>
            </div>
            <div className={styles.contact}>
                <span className={styles.label}>e-mail</span>
                <a href='mailto: mail@pt.team' className={styles.link}>mail@pt.team</a>
            </div>
        </div>
        <span className={styles.info}>
            ООО «Проект Хаб» ИНН 5260476190, КПП  526001001, ОГРН 1215200004216
        </span>
      </div>
    </div>
  </section>
);
