import React, { useState } from 'react';
import cx from 'classnames';
import { HUB_LINKS } from 'src/constants/Common';
import { ButtonLink } from '../ButtonLink';
import styles from './styles.module.scss';

type Props = {
  label: string;
  list: string[];
  className?: string;
};

export const DropdownInfo: React.FC<Props> = ({ label, list, className }) => {
  const [isViewDropdown, setIsViewDropdown] = useState(false);

  const handleViewDropdown = () => {
    setIsViewDropdown(!isViewDropdown);
  };

  return (
    <div className={cx(styles.dropdown, className)}>
      <div className={cx(styles.labelButton, isViewDropdown && styles.open)} onClick={handleViewDropdown}>{label}</div>
      {isViewDropdown &&
        <div className={cx(styles.content)}>
            <div className={styles.list}>
                {list.map((item, index) => (
                <span key={index} className={styles.item}>
                    {item}
                </span>
                ))}
            </div>
            <ButtonLink label='начать работу' className={styles.startLink} href={HUB_LINKS.registration} />
        </div>
      }
    </div>
  );
};
