import React from 'react';
import cx from 'classnames';
import { BlockLabel } from '../BlockLabel';
import { NUMBERS } from 'src/constants/Common';
import styles from './styles.module.scss';

export const Numbers: React.FC = () => (
  <section className={styles.numbers} id='numbers'>
    <div className={cx(styles.wrapper, 'content-center')}>
        <BlockLabel label='Цифры' className={styles.blockLabel} />
        <div className={styles.list}>
            {NUMBERS.map((item, index) =>
                <div key={index} className={styles.item}>
                    <div className={styles.top}>
                        <span className={styles.label}>{item.label}</span>
                        <span className={styles.count}>{item.count}</span>
                    </div>
                    <span className={styles.desc}>{item.desc}</span>
                </div>
            )}
        </div>
    </div>
  </section>
);
