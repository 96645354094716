import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string | JSX.Element;
  className?: string;
  href: string;
};

export const LoginButton: React.FC<Props> = ({ label, className, href }) => (
  <a href={href} rel='noreferrer' className={cx(styles.button, className)}>
    {label}
  </a>
);
