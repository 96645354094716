export const MAIN_MENU = [
    {
        title: 'Принципы',
        anchor: 'principles'
    },
    {
        title: 'Для кого',
        anchor: 'forWhom'
    },
    {
        title: 'Цифры',
        anchor: 'numbers'
    },
    {
        title: 'Возможности',
        anchor: 'possibilities'
    },
    {
        title: 'Контакты',
        anchor: 'contacts'
    }
];

export const FUNCTIONALITY = [
    {
        title: 'Безопасность',
        desc: 'все данные надежно защищены'
    },
    {
        title: 'Скорость',
        desc: 'быстро найдёте нужного специалиста или заказ'
    },
    {
        title: 'Выбор',
        desc: 'лучшие кандидаты, добросовестные клиенты'
    },
    {
        title: 'Комфорт',
        desc: 'онлайн-чаты, совещания, протоколы, ЭДО и ЭЦП'
    },
    {
        title: 'Контроль',
        desc: 'вшиты все инструменты ведения проекта '
    },
    {
        title: 'Простота',
        desc: 'понятный и удобный интерфейс'
    },
    {
        title: 'Гибкость',
        desc: 'уровни доступа к данным'
    },
    {
        title: 'Открытость',
        desc: 'новые функции по запросу'
    }
];

export const FOR_CUSTOMERS = {
    title: 'для заказчиков',
    list: [
        'Размещайте заказы и находите проверенных исполнителей',
        'Формируйте удаленные команды',
        'Создавайте онлайн-совещания и протоколы',
        'Контролируйте ход проекта',
        'Обменивайтесь электронными документами и подписывайте их на платформе',
        'Распределяйте уровни доступа к данным'
    ]
};

export const FOR_PERFORMERS = {
    title: 'для исполнителей',
    list: [
        'Регистрируйте профиль и находите заказы от проверенных клиентов',
        'Работайте из любой точки мира в облаке через системы удаленного доступа',
        'Общайтесь в онлайн-чатах, подписывайте документы c помощью ЭЦП',
        'Ведите несколько проектов одновременно и легко переключайтесь между ними',
        'Не переживайте за оплату работы и за защиту данных, платформа дает гарантии и контролирует выплаты',
        'Общайтесь с коллегами, обменивайтесь опытом, развивайтесь'
    ]
};

export const NUMBERS = [
    {
        label: '',
        count: '3500',
        desc: 'компаний нуждаются в дополнительных проектировщиках'
    },
    {
        label: 'более',
        count: '100 000',
        desc: 'квалифицированных проектировщиков готовы брать удаленные проекты'
    },
    {
        label: '',
        count: '15%',
        desc: 'годовой рост потребности компаний в специалистах по проектированию'
    },
];

export const POSSIBILITIES = [
    {
        first: 'один',
        strong: 'сервис',
        text: 'для всех новых контактов на рынке',
        list: [
            'Анкета специалистов составлена с учетом специфики проектного рынка',
            'ГИП за считанные секунды оценит полную квалификацию кандидата',
            'Нет случайных резюме из других сфер бизнеса',
            'Только добросовестные клиенты с хорошей репутацией',
            'Возможность общаться и работать в глобальных проектах'
        ]
    },
    {
        first: 'один',
        strong: 'канал',
        text: 'для всех рабочих задач',
        list: [
            'Удаленные рабочие места и работа в облаке через системы удаленного доступа',
            'Серверные мощности (сервер Selectel, резервное копирование, защищенный HTTPS-протокол шифрования, защита данных и ограничение доступа)',
            'Онлайн чаты, протоколы, совещания',
            'ЭДО и ЭЦП',
            'Все инструменты в одном месте, включая программы, библиотеки и справочники'
        ]
    },
    {
        first: 'один',
        strong: 'принцип',
        text: 'для всех данных',
        list: [
            'Безопасная передача данных',
            'Защита от потери ценной информации по проекту',
            'Защита личной информации',
            'Хронология и версионность документов',
            'Уровни доступа к информации по проекту'
        ]
    },
    {
        first: 'один',
        strong: 'процесс',
        text: 'для всех проектов',
        list: [
            'Настраивайте контрольные точки по каждому этапу проекта',
            'Используйте системы уведомлений для контроля над сроками',
            'Общайтесь в чатах для координации совместной работы',
            'Обменивайтесь электронными документами и подписывайте их на платформе',
            'Проводите оплаты и получайте вознаграждения через безопасную систему оплаты на платформе'
        ]
    },
    {
        first: 'один',
        strong: 'клик',
        text: 'для всех запросов',
        list: [
            'Служба поддержки всегда на связи',
            'Круглосуточный доступ к платформе',
            'Веб-версия и мобильное приложение',
            'Гибкость платформы: новые функции по запросу'
        ]
    }
];

export const DOCS = [
    {
        label: 'Инструкция по установке и началу работы',
        link: 'https://download.pt.team/GettingStarted.pdf'
    },
    {
        label: 'Описание жизненного цикла поддержки и обслуживания',
        link: 'https://download.pt.team/SoftwareMaintenance.pdf'
    },
    {
        label: 'Описание функциональных характеристик',
        link: 'https://download.pt.team/SoftwareCharacteristics.pdf'
    },
    {
        label: 'Общее руководство',
        link: 'https://download.pt.team/GeneralLeadership.pdf'
    },
    {
        label: 'Политика обработки персональных данных',
        link: 'https://download.pt.team/PrivacyPolicy.pdf'
    },
    {
        label: 'Пользовательское соглашение',
        link: 'https://download.pt.team/TermsOfUse.pdf'
    }
];

export const MOBILE_MAX_WIDTH = 1279;

export const MESSENGERS_LINKS = [
    'https://vk.com/ptteamgroup',
    'https://t.me/ptteamgroup',
    'https://dzen.ru/ptteam'
]

export const HUB_LINKS = {
    login: 'https://pt.team/login',
    registration: 'https://pt.team/registration',
    // login: 'https://phub.cloudstechnologies.com/login',
    // registration: 'https://phub.cloudstechnologies.com/registration'
}
