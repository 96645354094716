import React from 'react';
import { BlockLabel } from '../BlockLabel';
import { FUNCTIONALITY } from 'src/constants/Common';
import styles from './styles.module.scss';

export const Priciples: React.FC = () => (
  <section className={styles.principles} id='principles'>
    <div className="content-center">
        <BlockLabel label='Принципы' />
        <h3 className={styles.title}>
            Функционал платформы <br /> создан на основе исследования <br /> запросов проектной отрасли:
        </h3>
        <div className={styles.list}>
            {FUNCTIONALITY.map((item, index) =>
                <div key={index} className={styles.item}>
                    <span className={styles.label}>{item.title}</span>
                    <span className={styles.desc}>{item.desc}</span>
                </div>
            )}
        </div>
    </div>
  </section>
);
