import React from 'react';
import { DOCS } from 'src/constants/Common';
import { BlockLabel } from '../BlockLabel';
import styles from './styles.module.scss';

export const Docs: React.FC = () => (
  <section className={styles.docs}>
    <div className='content-center'>
      <BlockLabel label='Приложения' />
      <div className={styles.list}>
        {DOCS.map((link, index) => (
          <a className={styles.link} key={index} href={link.link} target='_blank' rel='noreferrer'>
            {link.label}
          </a>
        ))}
      </div>
    </div>
  </section>
);
