import React from 'react';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Vk, Tg, Dzen } from 'src/icons/icons';
import { LoginButton } from 'src/components/LoginButton';
import { MAIN_MENU, MOBILE_MAX_WIDTH, MESSENGERS_LINKS, HUB_LINKS } from 'src/constants/Common';
import logoSrc from 'src/img/logo.svg';
import logoMobileSrc from 'src/img/logo-mobile.svg';
import styles from './styles.module.scss';

type Props = {
  isSider: boolean;
  isViewSide: () => void;
};

export const Header: React.FC<Props> = ({ isSider, isViewSide }) => {
  const isMobileView = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  return (
    <header className={styles.header}>
      <div className="content-center">
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <a className={styles.logo} href='/'><img src={!isMobileView ?logoSrc : logoMobileSrc} alt="" /></a>
            {!isMobileView &&
              <>
                <nav className={styles.menu}>
                  {MAIN_MENU.map((item, index) =>
                    <a className={styles.navLink} href={`#${item.anchor}`} key={index}>
                      {item.title}
                    </a>
                  )}
                </nav>
                <div className={styles.messengers}>
                  {MESSENGERS_LINKS.map((link, index) =>
                    <a rel='noreferrer' target='_blank' href={link} key={index}>
                      {index === 0 && <Vk />}
                      {index === 1 && <Tg />}
                      {index === 2 && <Dzen />}
                    </a>
                  )}
                </div>
              </>
            }
          </div>
          <div className={styles.userActions}>
            {!isMobileView ?
              <>
                <LoginButton href={HUB_LINKS.login} label='Войти' />
                <LoginButton href={HUB_LINKS.registration} label='Регистрация' />
              </>
              :
              <div className={cx(isSider && styles.active, styles.burger)} onClick={isViewSide}>
                {!isSider && <span></span>}
              </div>
            }
          </div>
        </div>
      </div>
    </header>
  );
};
