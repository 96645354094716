import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH, HUB_LINKS } from 'src/constants/Common';
import { ServiceIcon } from 'src/icons/icons';
import { ButtonLink } from '../ButtonLink';
import styles from './styles.module.scss';

export const TopService: React.FC = () => {
  const isMobileView = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  return (
    <section className={styles.topService}>
      <div className="content-center">
        <div className={styles.wrapper}>
          <div className={styles.title}>
            один
            <span className={styles.bolder}>сервис</span>
            <ServiceIcon />
            {isMobileView && <br />}
            для всех
            {' '}
            {!isMobileView && <br />}
            задач проектирования
          </div>
          <ButtonLink label='начать работу' href={HUB_LINKS.registration} />
        </div>
      </div>
      {isMobileView && <span className={styles.mobileLines}></span>}
    </section>
)};
