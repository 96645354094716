import React from 'react';
import { BlockLabel } from '../BlockLabel';
import { FOR_CUSTOMERS, FOR_PERFORMERS } from 'src/constants/Common';
import { Customers, Performers } from 'src/icons/icons';
import { DropdownInfo } from '../DropdownInfo';
import styles from './styles.module.scss';

export const ForWhom: React.FC = () => (
  <section className={styles.forWhom} id='forWhom'>
    <div className='content-center'>
      <BlockLabel label='Для кого Project Team?' />
      <div className={styles.content}>
        <div className={styles.dropdown}>
            <Customers />
            <DropdownInfo label={FOR_CUSTOMERS.title} list={FOR_CUSTOMERS.list} />
        </div>
        <div className={styles.dropdown}>
            <Performers />
            <DropdownInfo label={FOR_PERFORMERS.title} list={FOR_PERFORMERS.list} />
        </div>
      </div>
    </div>
  </section>
);
