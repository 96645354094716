import React from 'react';
import styles from './styles.module.scss';

export const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <div className='content-center'>
      <div className={styles.content}>
        <span className={styles.text}>
          Программное обеспечение распространяется в виде интернет-сервиса, специальные действия по его установке на стороне пользователя не требуются.
        </span>
        <span className={styles.text}>
          Стоимость неисключительной лицензии, порядок и условия её оплаты, а также возможность использования программного обеспечения на условиях открытой лицензии или иного безвозмездного лицензионного договора рассчитываются индивидуально.
        </span>
        <span className={styles.text}>
          Программное обеспечение Project team внесено в Единый реестр российских программ для электронных вычислительных машин и баз данных. Запись в реестре №13965 от 14.06.2022 произведена на основании поручения Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации от 14.06.2022 по протоколу заседания экспертного совета от 06.06.2022 №727пр
        </span>
      </div>
    </div>
  </footer>
);
