import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string;
  className?: string
};

export const BlockLabel: React.FC<Props> = ({
  label,
  className
}) => (
  <h2 className={cx(styles.label, className)}>
    {label}
  </h2>
);
