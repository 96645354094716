import React from 'react';
import cx from 'classnames';
import { VkMob, TgMob, DzenMob } from 'src/icons/icons';
import { LoginButton } from 'src/components/LoginButton';
import { MAIN_MENU, MESSENGERS_LINKS, HUB_LINKS } from 'src/constants/Common';
import styles from './styles.module.scss';

type Props = {
    isClosedSider: () => void;
};

export const MobileSider: React.FC<Props> = ({isClosedSider}) => {

  return (
    <div className={cx(styles.sider, 'animate__animated animate__slideInRight')}>
        <nav className={styles.menu}>
            {MAIN_MENU.map((item, index) =>
                <a className={styles.navLink} href={`#${item.anchor}`} key={index} onClick={isClosedSider}>
                    {item.title}
                </a>
            )}
        </nav>
        <div className={styles.userActions}>
            <LoginButton className={styles.login} href={HUB_LINKS.login} label='Войти' />
            <LoginButton className={styles.reg} href={HUB_LINKS.login} label='Регистрация' />
            <div className={styles.messengers}>
                {MESSENGERS_LINKS.map((link, index) =>
                    <a rel='noreferrer' target='_blank' href={link} key={index}>
                        {index === 0 && <VkMob />}
                        {index === 1 && <TgMob />}
                        {index === 2 && <DzenMob />}
                    </a>
                )}
            </div>
        </div>
    </div>
  );
};
