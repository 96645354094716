import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from 'src/constants/Common';
import { TopService } from 'src/components/TopService';
import { Priciples } from 'src/components/Principles';
import { ForWhom } from 'src/components/ForWhom';
import { Numbers } from 'src/components/Numbers';
import { Possibilities } from 'src/components/Possibilities';
import { Contacts } from 'src/components/Contacts';
import { Docs } from 'src/components/Docs';
import styles from './styles.module.scss';
import { Layout } from 'src/containers/Layout';

const App: React.FC = () => {
  const isMobileView = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
  const [isViewSide, setIsViewSide] = useState(false);
  const body = document.querySelector('body');

  const handleViewSide = () => {
    setIsViewSide(!isViewSide);
    !isViewSide ? body?.classList.add('noScroll') : body?.classList.remove('noScroll');
  };

  const handleClosedSide = () => {
    setIsViewSide(false);
    body?.classList.remove('noScroll');
  };

  useEffect(()=>{
		if (!isMobileView) {
      setIsViewSide(false);
      body?.classList.remove('noScroll');
    }
	}, [isMobileView, body])

  return (
    <Layout isClosedSider={handleClosedSide} isViewSider={handleViewSide} isSider={isViewSide}>
      <div className={styles.contentWrapper}>
        <TopService />
        <Priciples />
        <ForWhom />
        <Numbers />
        <Possibilities />
        <Contacts />
        <Docs />
      </div>
    </Layout>
  );
};

export default App;
