import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Slide1, Slide2, Slide3, Slide4, Slide5 } from 'src/icons/icons';
import cx from 'classnames';
import { BlockLabel } from '../BlockLabel';
import { POSSIBILITIES } from 'src/constants/Common';
import styles from './styles.module.scss';

export const Possibilities: React.FC = () => (
  <section className={styles.possibilities} id='possibilities'>
    <div className={styles.top}>
      <div className='content-center'>
        <BlockLabel label='Возможности' />
      </div>
    </div>
    <Swiper
      slidesPerView={1}
      spaceBetween={24}
      grabCursor={true}
      pagination={{
        el: '.swiper-pager',
        clickable: true
      }}
      breakpoints={{
        1280: {
          slidesPerView: 'auto',
          spaceBetween: 48
        },
      }}
      modules={[Pagination]}
    >
      {POSSIBILITIES.map((item, index) => (
        <SwiperSlide key={index} className={styles.slide}>
          <div className={styles.slideItem}>
            <div className={styles.slideTop}>
              <span>{item.first} </span>
              <span className={styles.stronger}>{item.strong}</span>
              {index === 0 && <Slide1 />}
              {index === 1 && <Slide2 />}
              {index === 2 && <Slide3 />}
              {index === 3 && <Slide4 />}
              {index === 4 && <Slide5 />}
              <span>{item.text}</span>
            </div>
            <ul className={styles.slideList}>
              {item.list.map((item, index) => (
                <li key={index} className={styles.item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    <div className={cx(styles.pager, 'swiper-pager')} />
  </section>
);
