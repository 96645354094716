export const ServiceIcon = () => (
  <svg width='168' height='181' viewBox='0 0 168 181' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M57.0405 178.728L99.2732 154.339L99.0431 124.352'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M141.276 178.728L99.0432 154.339'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M71.5867 124.352H152.32H165.511V2H152.32H29.7375H16.5461V56.5803'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M71.5868 64.7867L57.0405 56.376H16.5462L2 64.7867V170.343L16.5462 178.728H57.0405L71.5868 170.343V64.7867Z'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M36.7934 165.153C40.0972 165.153 42.7756 162.475 42.7756 159.171C42.7756 155.867 40.0972 153.189 36.7934 153.189C33.4896 153.189 30.8113 155.867 30.8113 159.171C30.8113 162.475 33.4896 165.153 36.7934 165.153Z'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M71.9705 110.752H165.511'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M124.352 70.0015C139.375 70.0015 151.553 57.8233 151.553 42.8008C151.553 27.7783 139.375 15.6001 124.352 15.6001C109.329 15.6001 97.1514 27.7783 97.1514 42.8008C97.1514 57.8233 109.329 70.0015 124.352 70.0015Z'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M124.352 42.8013L105.128 62.0258C110.215 67.1132 117.143 69.9764 124.352 69.9764C139.359 69.9764 151.527 57.8077 151.527 42.8013H124.352V42.8013Z'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.175 29.2007H83.5509'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.175 42.8013H83.5509'
      stroke='#121212'
      strokeWidth='4'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Customers = () => (
  <svg width='262' height='227' viewBox='0 0 262 227' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M130.171 1.43512L131 0L132.155 2L213.606 143.078H213.806V143.425L260.615 224.5L261.77 226.5H259.46H171.961L170.265 226.805L170.21 226.5H91.2768L91.2219 226.805L89.5261 226.5H2.53963H0.230225L1.38493 224.5L48.1935 143.425V143.078H48.3939L129.554 2.50548L129.759 1.36098L130.171 1.43512ZM49.5486 145.078L3.69432 224.5H89.6046L103.897 145.078H49.5486ZM104.257 143.078H50.7033L128.508 8.31684L104.257 143.078ZM105.929 145.078L91.6367 224.5H169.851L155.558 145.078H105.929ZM155.198 143.078H106.289L130.744 7.18439L155.198 143.078ZM157.59 145.078L171.883 224.5H258.306L212.452 145.078H157.59ZM211.297 143.078H157.23L132.747 7.0264L211.297 143.078Z'
      fill='#3BE65A'
    />
  </svg>
);

export const Performers = () => (
  <svg width='227' height='227' viewBox='0 0 227 227' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='113.5' cy='113.5' r='112.5' stroke='#3BE65A' strokeWidth='2' />
    <path
      d='M226 113.5C226 134.195 213.557 153.048 193.198 166.772C172.844 180.493 144.67 189.011 113.5 189.011C82.33 189.011 54.1556 180.493 33.8023 166.772C13.4431 153.048 1 134.195 1 113.5C1 92.8052 13.4431 73.9519 33.8023 60.2276C54.1556 46.5073 82.33 37.9888 113.5 37.9888C144.67 37.9888 172.844 46.5073 193.198 60.2276C213.557 73.9519 226 92.8052 226 113.5Z'
      stroke='#3BE65A'
      strokeWidth='2'
    />
    <path
      d='M226 113.5C226 116.925 223.251 120.464 217.565 123.885C211.941 127.269 203.737 130.351 193.51 132.953C173.072 138.154 144.784 141.382 113.5 141.382C82.216 141.382 53.9278 138.154 33.49 132.953C23.2635 130.351 15.0589 127.269 9.43491 123.885C3.7489 120.464 1 116.925 1 113.5C1 110.076 3.7489 106.536 9.43491 103.115C15.0589 99.7314 23.2635 96.6491 33.49 94.0468C53.9278 88.8461 82.216 85.6184 113.5 85.6184C144.784 85.6184 173.072 88.8461 193.51 94.0468C203.737 96.6491 211.941 99.7314 217.565 103.115C223.251 106.536 226 110.076 226 113.5Z'
      stroke='#3BE65A'
      strokeWidth='2'
    />
    <path
      d='M113.5 226C92.8053 226 73.952 213.557 60.2277 193.198C46.5075 172.844 37.9889 144.67 37.9889 113.5C37.9889 82.33 46.5075 54.1556 60.2277 33.8023C73.952 13.4431 92.8053 0.999996 113.5 0.999997C134.195 0.999998 153.048 13.4431 166.772 33.8023C180.493 54.1556 189.011 82.33 189.011 113.5C189.011 144.67 180.493 172.844 166.772 193.198C153.048 213.557 134.195 226 113.5 226Z'
      stroke='#3BE65A'
      strokeWidth='2'
    />
    <path
      d='M113.5 226C110.075 226 106.536 223.251 103.115 217.565C99.7312 211.941 96.6488 203.737 94.0466 193.51C88.8459 173.072 85.6182 144.784 85.6182 113.5C85.6182 82.216 88.8459 53.9278 94.0466 33.49C96.6489 23.2635 99.7312 15.0589 103.115 9.43491C106.536 3.7489 110.075 0.999999 113.5 0.999999C116.924 0.999999 120.464 3.7489 123.885 9.43491C127.269 15.0589 130.351 23.2635 132.953 33.49C138.154 53.9278 141.382 82.216 141.382 113.5C141.382 144.784 138.154 173.072 132.953 193.51C130.351 203.737 127.269 211.941 123.885 217.565C120.464 223.251 116.924 226 113.5 226Z'
      stroke='#3BE65A'
      strokeWidth='2'
    />
  </svg>
);

export const Slide1 = () => (
  <svg width='105' height='114' viewBox='0 0 105 114' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M35.9984 111.999L62.0854 96.8191L61.9433 78.1541'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M88.0309 111.998L61.9438 96.8181'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M44.9834 78.1557H94.8519H103V1.99988H94.8519H19.1333H10.9851V35.9724'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M44.9835 41.0794L35.9983 35.8442H10.9852L2 41.0794V106.781L10.9852 112H35.9983L44.9835 106.781V41.0794Z'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23.4915 103.553C25.5323 103.553 27.1867 101.886 27.1867 99.8295C27.1867 97.7731 25.5323 96.106 23.4915 96.106C21.4507 96.106 19.7964 97.7731 19.7964 99.8295C19.7964 101.886 21.4507 103.553 23.4915 103.553Z'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M45.2205 69.6906H103'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M77.5765 44.3269C86.8559 44.3269 94.3783 36.7468 94.3783 27.3963C94.3783 18.0458 86.8559 10.4657 77.5765 10.4657C68.2972 10.4657 60.7748 18.0458 60.7748 27.3963C60.7748 36.7468 68.2972 44.3269 77.5765 44.3269Z'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M77.5772 27.395L65.7023 39.3609C68.8447 42.5275 73.1241 44.3096 77.5772 44.3096C86.8466 44.3096 94.3632 36.7354 94.3632 27.395H77.5772V27.395Z'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.7866 18.9317H52.3744'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.7866 27.395H52.3744'
      stroke='#CBCBCB'
      strokeWidth='2.44337'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Slide2 = () => (
  <svg width='80' height='93' viewBox='0 0 80 93' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M39.89 39.5071L36.0537 32.8633'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M31.9818 53.1914L28.1455 59.8352'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M47.7988 53.1914H55.4606'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.8896 12.1172V39.0888'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.26758 66.8848L31.6173 53.4043'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M71.5128 66.8848L48.1523 53.4043'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.8896 39.5071L43.7259 32.8633'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M31.9821 53.1914H24.3203'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M47.7988 53.1914L51.6244 59.8352'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.8903 51.552C41.5059 51.552 42.8157 50.2422 42.8157 48.6266C42.8157 47.0109 41.5059 45.7012 39.8903 45.7012C38.2746 45.7012 36.9648 47.0109 36.9648 48.6266C36.9648 50.2422 38.2746 51.552 39.8903 51.552Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.154 27.5488L15.6079 28.9633L18.0511 30.3778V33.1961V36.025L15.6079 37.4395L13.154 38.8433L10.7108 37.4395L8.26758 36.025V33.1961V30.3778L10.7108 28.9633L13.154 27.5488Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.8893 73.8516L42.3325 75.2553L44.7757 76.6698V79.4988V82.317L42.3325 83.7315L39.8893 85.146L37.4461 83.7315L34.9922 82.317V79.4988V76.6698L37.4461 75.2553L39.8893 73.8516Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.6149 27.5488L69.0688 28.9633L71.512 30.3778V33.1961V36.025L69.0688 37.4395L66.6149 38.8433L64.1717 37.4395L61.7285 36.025V33.1961V30.3778L64.1717 28.9633L66.6149 27.5488Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.8896 51.5508V73.8503'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.0508 36.0234L37.3606 47.1679M42.4185 47.1679L61.7284 36.0234'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.9573 1.83203L59.5214 13.1244L79 24.4168V46.916V69.5008L59.5214 80.7932L39.9573 92L20.4786 80.7932L1 69.5008V46.916V24.4168L20.4786 13.1244L39.9573 1.83203Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Slide3 = () => (
  <svg width='79' height='94' viewBox='0 0 79 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M39.4935 50.6211V92.4431L9.05566 74.8009C9.05566 68.0503 9.09561 59.4355 9.09561 52.2588L31.185 65.0145L39.4935 50.6211V50.6211Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.678 52.392V75.014L39.4932 92.4431V50.6211L47.815 65.0145L69.678 52.392Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M47.815 65.014L39.4932 50.6206L69.678 33.1914L77.9998 47.5848L47.815 65.014Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M31.1848 65.014L39.4933 50.6206L9.3218 33.1914L1 47.5848L31.1848 65.014Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.6782 33.1923L57.6548 40.1427V26.2419L69.6782 33.1923V33.1923ZM22.2505 40.6486L9.32178 33.1923L22.2505 25.7227V40.6486Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.3218 33.1914L1 18.7847L31.1848 1.35547L38.801 14.5505H22.2506V25.7217L9.3218 33.1914Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.6781 33.1914L77.9999 18.7847L47.8151 1.35547L40.199 14.5505H43.7274L57.6548 22.5927V26.241L69.6781 33.1914Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.2505 40.648L39.4933 50.6208L57.6548 40.648V22.593L43.7274 14.5508H22.2505V40.648Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.7275 14.5508V22.593H57.6549L43.7275 14.5508Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Slide4 = () => (
  <svg width='85' height='77' viewBox='0 0 85 77' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M45.0208 35.8164L42.4997 31.4589L51.6386 26.1777L54.1488 30.5352L45.0208 35.8164Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.9891 35.8164L42.4993 31.4589L33.3713 26.1777L30.8502 30.5352L39.9891 35.8164Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33.3709 23.4393V26.1777'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M51.6385 23.4393V26.1777'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.1701 10.2365V16.3979V22.5484L47.8346 25.6237L42.499 28.7099L37.1634 25.6237L31.8387 22.5484V16.3979V10.2365V7.16118V4.07504L34.5011 2.54283L37.1743 0.999756H47.8346L50.4969 2.54283L53.1701 4.07504V7.16118V10.2365Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.8387 4.07504L34.5011 2.54283L37.1743 0.999756H47.8346L50.4969 2.54283L53.1701 4.07504V11.0189L50.2035 7.16118H34.7945L31.8387 11.0189V4.07504Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M74.8726 75.339L72.3515 70.9815L81.4904 65.7111L84.0007 70.0687L74.8726 75.339Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.841 75.339L72.3512 70.9815L63.2231 65.7111L60.7129 70.0687L69.841 75.339Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M63.2227 62.9609V65.7102'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M81.4902 62.9609V65.7102'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M83.022 49.759V55.9205V62.0819L77.6864 65.1572L72.3509 68.2325L67.0262 65.1572L61.6906 62.0819V55.9205V49.759V46.6838V43.6085L64.353 42.0654L67.0262 40.5332H77.6864L80.3488 42.0654L83.022 43.6085V46.6838V49.759Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6906 43.6085L64.353 42.0654L67.0262 40.5332H77.6864L80.3488 42.0654L83.022 43.6085V50.5523L80.0554 46.6838H64.6464L61.6906 50.5523V43.6085Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.9819 32.0469L24.1899 42.0986'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M55.0181 32.0469L60.8209 42.0986'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.1705 75.339L12.6494 70.9815L21.7883 65.7111L24.2985 70.0687L15.1705 75.339Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.1389 75.339L12.6491 70.9815L3.52107 65.7111L1 70.0687L10.1389 75.339Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.52051 62.9631V65.7124'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.7881 62.9631V65.7124'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3199 49.7594V55.9208V62.0823L17.9843 65.1575L12.6488 68.2328L7.32409 65.1575L1.98853 62.0823V55.9208V49.7594V46.6841V43.6089L4.65088 42.0658L7.32409 40.5336H17.9843L20.6467 42.0658L23.3199 43.6089V46.6841V49.7594Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.98853 43.6089L4.65088 42.0658L7.32409 40.5336H17.9843L20.6467 42.0658L23.3199 43.6089V50.5527L20.3533 46.6841H4.94427L1.98853 50.5527V43.6089Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26.2437 70.0684H58.7677'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const Slide5 = () => (
  <svg width='92' height='92' viewBox='0 0 92 92' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.0201 19.3665C14.0489 19.3665 15.6936 17.7218 15.6936 15.693C15.6936 13.6642 14.0489 12.0195 12.0201 12.0195C9.99133 12.0195 8.34668 13.6642 8.34668 15.693C8.34668 17.7218 9.99133 19.3665 12.0201 19.3665Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M30.3878 19.3665C32.4166 19.3665 34.0613 17.7218 34.0613 15.693C34.0613 13.6642 32.4166 12.0195 30.3878 12.0195C28.359 12.0195 26.7144 13.6642 26.7144 15.693C26.7144 17.7218 28.359 19.3665 30.3878 19.3665Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M48.755 19.3665C50.7838 19.3665 52.4285 17.7218 52.4285 15.693C52.4285 13.6642 50.7838 12.0195 48.755 12.0195C46.7262 12.0195 45.0815 13.6642 45.0815 15.693C45.0815 17.7218 46.7262 19.3665 48.755 19.3665Z'
      stroke='#CBCBCB'
      strokeWidth='2'
      strokeMiterlimit='22.9256'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 1V0C0.447715 0 0 0.447715 0 1L1 1ZM60.6939 1H61.6939C61.6939 0.447715 61.2462 0 60.6939 0V1ZM1 60.6939H0C0 61.2462 0.447715 61.6939 1 61.6939L1 60.6939ZM30.3877 60.6939V61.6939C30.94 61.6939 31.3877 61.2462 31.3877 60.6939H30.3877ZM30.3877 31.3066V30.3066C29.8354 30.3066 29.3877 30.7544 29.3877 31.3066H30.3877ZM60.6939 31.3066V32.3066C61.2462 32.3066 61.6939 31.8589 61.6939 31.3066H60.6939ZM1 2H60.6939V0H1V2ZM2 60.6939V1H0V60.6939H2ZM30.3877 59.6939H1V61.6939H30.3877V59.6939ZM31.3877 60.6939V31.3066H29.3877V60.6939H31.3877ZM30.3877 32.3066H60.6939V30.3066H30.3877V32.3066ZM59.6939 1V31.3066H61.6939V1H59.6939Z'
      fill='#CBCBCB'
    />
    <path
      d='M30.3878 31.3066V30.3066C29.8355 30.3066 29.3878 30.7543 29.3878 31.3066H30.3878ZM90.0817 31.3066L90.7888 32.0137C91.0748 31.7277 91.1604 31.2976 91.0056 30.9239C90.8508 30.5502 90.4862 30.3066 90.0817 30.3066V31.3066ZM30.3878 91.0005H29.3878C29.3878 91.4049 29.6315 91.7696 30.0051 91.9243C30.3788 92.0791 30.8089 91.9936 31.0949 91.7076L30.3878 91.0005ZM50.953 70.4353L51.6601 69.7281C51.2696 69.3376 50.6364 69.3376 50.2459 69.7281L50.953 70.4353ZM69.7122 89.1945L69.0051 89.9016C69.3957 90.2921 70.0288 90.2921 70.4194 89.9016L69.7122 89.1945ZM89.1938 69.713L89.9009 70.4201C90.0884 70.2325 90.1938 69.9782 90.1938 69.713C90.1938 69.4478 90.0884 69.1934 89.9009 69.0059L89.1938 69.713ZM70.4345 50.9537L69.7274 50.2466C69.3369 50.6372 69.3369 51.2703 69.7274 51.6609L70.4345 50.9537ZM30.3878 32.3066H90.0817V30.3066H30.3878V32.3066ZM31.3878 91.0005V31.3066H29.3878V91.0005H31.3878ZM50.2459 69.7281L29.6807 90.2934L31.0949 91.7076L51.6601 71.1424L50.2459 69.7281ZM70.4194 88.4874L51.6601 69.7281L50.2459 71.1424L69.0051 89.9016L70.4194 88.4874ZM88.4867 69.0059L69.0051 88.4874L70.4194 89.9016L89.9009 70.4201L88.4867 69.0059ZM69.7274 51.6609L88.4867 70.4201L89.9009 69.0059L71.1416 50.2466L69.7274 51.6609ZM89.3746 30.5995L69.7274 50.2466L71.1416 51.6609L90.7888 32.0137L89.3746 30.5995Z'
      fill='#CBCBCB'
    />
  </svg>
);

export const Vk = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.3022 0H9.7118C1.8662 0 0 1.8662 0 9.6978V18.2882C0 26.1324 1.8522 28 9.6978 28H18.2882C26.1324 28 28 26.1478 28 18.3022V9.7118C28 1.8662 26.1478 0 18.3022 0ZM22.6044 19.978H20.5618C19.789 19.978 19.5566 19.3522 18.1706 17.9662C16.961 16.8 16.45 16.6544 16.1434 16.6544C15.7206 16.6544 15.6044 16.7706 15.6044 17.3544V19.1912C15.6044 19.6882 15.4434 19.9794 14.1456 19.9794C12.8861 19.8948 11.6647 19.5121 10.582 18.8629C9.49938 18.2138 8.58644 17.3167 7.9184 16.2456C6.33244 14.2716 5.22892 11.9548 4.6956 9.4794C4.6956 9.1728 4.8118 8.8956 5.3956 8.8956H7.4368C7.9618 8.8956 8.1508 9.1294 8.3566 9.6684C9.3478 12.586 11.039 15.1228 11.725 15.1228C11.9882 15.1228 12.103 15.0066 12.103 14.35V11.3456C12.0162 9.975 11.2882 9.8588 11.2882 9.3632C11.2976 9.23248 11.3575 9.11054 11.4552 9.02325C11.553 8.93596 11.6809 8.89017 11.8118 8.8956H15.0206C15.4588 8.8956 15.6044 9.114 15.6044 9.639V13.6934C15.6044 14.1316 15.7934 14.2772 15.925 14.2772C16.1882 14.2772 16.3912 14.1316 16.8728 13.65C17.9072 12.3885 18.7523 10.9831 19.3816 9.478C19.4459 9.29721 19.5676 9.14243 19.7281 9.03727C19.8887 8.93211 20.0792 8.88236 20.2706 8.8956H22.3132C22.925 8.8956 23.0552 9.2022 22.925 9.639C22.1823 11.3028 21.2633 12.8822 20.1838 14.35C19.964 14.686 19.8758 14.861 20.1838 15.2544C20.3868 15.561 21.1022 16.1588 21.5838 16.7272C22.2837 17.4253 22.8648 18.233 23.3044 19.1184C23.4794 19.6868 23.1868 19.978 22.6044 19.978Z'
      fill='#999A9D'
    />
  </svg>
);

export const VkMob = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1549 0H7.85526C2.10182 0 0.733276 1.36855 0.733276 7.11172V13.4113C0.733276 19.1638 2.09156 20.5333 7.84499 20.5333H14.1446C19.897 20.5333 21.2666 19.175 21.2666 13.4216V7.12198C21.2666 1.36855 19.9083 0 14.1549 0ZM17.3098 14.6505H15.8119C15.2452 14.6505 15.0748 14.1916 14.0584 13.1752C13.1713 12.32 12.7966 12.2132 12.5718 12.2132C12.2617 12.2132 12.1765 12.2984 12.1765 12.7266V14.0735C12.1765 14.438 12.0584 14.6516 11.1067 14.6516C10.1831 14.5895 9.28738 14.3089 8.49344 13.8328C7.69949 13.3568 7.02999 12.6989 6.5401 11.9134C5.37706 10.4658 4.56782 8.76684 4.17672 6.95156C4.17672 6.72672 4.26193 6.52344 4.69005 6.52344H6.18693C6.57193 6.52344 6.71053 6.69489 6.86145 7.09016C7.58833 9.22973 8.82854 11.0901 9.33161 11.0901C9.52462 11.0901 9.60881 11.0048 9.60881 10.5233V8.3201C9.54515 7.315 9.01129 7.22978 9.01129 6.86634C9.01815 6.77048 9.06207 6.68106 9.13376 6.61705C9.20544 6.55303 9.29924 6.51946 9.39526 6.52344H11.7484C12.0697 6.52344 12.1765 6.6836 12.1765 7.0686V10.0418C12.1765 10.3632 12.3151 10.4699 12.4116 10.4699C12.6046 10.4699 12.7535 10.3632 13.1067 10.01C13.8652 9.08492 14.485 8.05428 14.9464 6.95053C14.9936 6.81795 15.0829 6.70444 15.2006 6.62733C15.3183 6.55021 15.458 6.51373 15.5984 6.52344H17.0963C17.5449 6.52344 17.6404 6.74828 17.5449 7.0686C17.0003 8.28872 16.3264 9.44692 15.5347 10.5233C15.3735 10.7697 15.3089 10.8981 15.5347 11.1866C15.6836 11.4114 16.2082 11.8498 16.5614 12.2666C17.0746 12.7785 17.5008 13.3709 17.8232 14.0202C17.9515 14.437 17.7369 14.6505 17.3098 14.6505Z" fill="#D9D9D9"/>
  </svg>
);

export const Tg = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.7118 0H18.3022C26.1478 0 28 1.8662 28 9.7118V18.3022C28 26.1478 26.1324 28 18.2882 28H9.6978C1.8522 28 0 26.1324 0 18.2882V9.6978C0 1.8662 1.8662 0 9.7118 0ZM19.7096 20.1549L22.2751 8.27421C22.5028 7.22656 21.8919 6.81752 21.1937 7.07451L6.11245 12.7802C5.08384 13.1756 5.09902 13.742 5.93757 13.999L9.79334 15.1844L18.7496 9.64623C19.1704 9.37016 19.5542 9.5234 19.2391 9.79888L11.9945 16.2273L11.7133 20.1364C12.1159 20.1364 12.2902 19.9653 12.4991 19.7602L14.385 17.9785L18.2942 20.8072C19.0114 21.202 19.5159 20.9933 19.7096 20.1549Z'
      fill='#999A9D'
    />
  </svg>
);

export const TgMob = () => (
  <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.78861 0H14.0882C19.8417 0 21.2 1.36855 21.2 7.12198V13.4216C21.2 19.175 19.8304 20.5333 14.078 20.5333H7.77834C2.02491 20.5333 0.666626 19.1638 0.666626 13.4113V7.11172C0.666626 1.36855 2.03517 0 7.78861 0ZM15.1204 14.7803L17.0017 6.06775C17.1687 5.29947 16.7208 4.9995 16.2087 5.18797L5.14914 9.37216C4.39483 9.66207 4.40596 10.0775 5.0209 10.2659L7.84846 11.1352L14.4164 7.07389C14.725 6.87144 15.0064 6.98382 14.7753 7.18583L9.46262 11.9L9.25645 14.7667C9.55167 14.7667 9.67947 14.6412 9.83265 14.4908L11.2157 13.1842L14.0825 15.2586C14.6083 15.5481 14.9784 15.3951 15.1204 14.7803Z'
      fill='#D9D9D9'
    />
  </svg>
);

export const Dzen = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1_3948)'>
      <path
        d='M16.7 16.7C14.5 18.97 14.34 21.8 14.15 28C19.93 28 23.92 27.98 25.98 25.98C27.98 23.92 28 19.74 28 14.15C21.8 14.35 18.97 14.5 16.7 16.7ZM0 14.15C0 19.74 0.02 23.92 2.02 25.98C4.08 27.98 8.07 28 13.85 28C13.65 21.8 13.5 18.97 11.3 16.7C9.03 14.5 6.2 14.34 0 14.15ZM13.85 0C8.08 0 4.08 0.02 2.02 2.02C0.02 4.08 0 8.26 0 13.85C6.2 13.65 9.03 13.5 11.3 11.3C13.5 9.03 13.66 6.2 13.85 0ZM16.7 11.3C14.5 9.03 14.34 6.2 14.15 0C19.93 0 23.92 0.02 25.98 2.02C27.98 4.08 28 8.26 28 13.85C21.8 13.65 18.97 13.5 16.7 11.3Z'
        fill='#999A9D'
      />
      <path
        d='M28 14.15V13.85C21.8 13.65 18.97 13.5 16.7 11.3C14.5 9.03 14.34 6.2 14.15 0H13.85C13.65 6.2 13.5 9.03 11.3 11.3C9.03 13.5 6.2 13.66 0 13.85V14.15C6.2 14.35 9.03 14.5 11.3 16.7C13.5 18.97 13.66 21.8 13.85 28H14.15C14.35 21.8 14.5 18.97 16.7 16.7C18.97 14.5 21.8 14.34 28 14.15Z'
        fill='#121212'
      />
    </g>
    <defs>
      <clipPath id='clip0_1_3948'>
        <rect width='28' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const DzenMob = () => (
  <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6_986)'>
      <path
        d='M13.1134 12.2467C11.5 13.9113 11.3827 15.9867 11.2434 20.5333C15.482 20.5333 18.408 20.5187 19.9187 19.052C21.3854 17.5413 21.4 14.476 21.4 10.3767C16.8534 10.5233 14.778 10.6333 13.1134 12.2467ZM0.866699 10.3767C0.866699 14.476 0.881366 17.5413 2.34803 19.052C3.8587 20.5187 6.7847 20.5333 11.0234 20.5333C10.8767 15.9867 10.7667 13.9113 9.15336 12.2467C7.4887 10.6333 5.41336 10.516 0.866699 10.3767ZM11.0234 0C6.79203 0 3.8587 0.0146666 2.34803 1.48133C0.881366 2.992 0.866699 6.05733 0.866699 10.1567C5.41336 10.01 7.4887 9.9 9.15336 8.28666C10.7667 6.622 10.884 4.54667 11.0234 0ZM13.1134 8.28666C11.5 6.622 11.3827 4.54667 11.2434 0C15.482 0 18.408 0.0146666 19.9187 1.48133C21.3854 2.992 21.4 6.05733 21.4 10.1567C16.8534 10.01 14.778 9.9 13.1134 8.28666Z'
        fill='#D9D9D9'
      />
      <path
        d='M21.4 10.3767V10.1567C16.8534 10.01 14.778 9.9 13.1134 8.28666C11.5 6.622 11.3827 4.54667 11.2434 0H11.0234C10.8767 4.54667 10.7667 6.622 9.15336 8.28666C7.4887 9.9 5.41336 10.0173 0.866699 10.1567V10.3767C5.41336 10.5233 7.4887 10.6333 9.15336 12.2467C10.7667 13.9113 10.884 15.9867 11.0234 20.5333H11.2434C11.39 15.9867 11.5 13.9113 13.1134 12.2467C14.778 10.6333 16.8534 10.516 21.4 10.3767Z'
        fill='#737373'
      />
    </g>
    <defs>
      <clipPath id='clip0_6_986'>
        <rect width='20.5333' height='20.5333' fill='white' transform='translate(0.866699)' />
      </clipPath>
    </defs>
  </svg>
);