import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string;
  href?: string;
  className?: string
};

export const ButtonLink: React.FC<Props> = ({
  label,
  href = 'https://ru.pt.team/registration',
  className
}) => (
  <a href={href} className={cx(styles.button, className)}>
    {label}
  </a>
);
