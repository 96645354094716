import React, { PropsWithChildren } from 'react';
import { Footer } from 'src/containers/Footer';
import { Header } from 'src/containers/Header';
import { MobileSider } from 'src/components/MobileSider';

type Props = {
  isSider: boolean;
  isViewSider: () => void;
  isClosedSider: () => void;
};

export const Layout: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <div className='layout-body'>
      <Header isViewSide={props.isViewSider} isSider={props.isSider} />
      {props.children}
      <Footer />
      {props.isSider && <MobileSider isClosedSider={props.isClosedSider} /> }
    </div>
  );
};

